import Countdown from "react-countdown";
import { renderer } from "components/CountdownRenderer";
import { useEffect, useState } from "react";
import Gnomes from "components/Gnomes";
// import ProgressBar from "components/ProgressBar";

const Count = ({startTime})=>{
    const renderTime = 5*60*1000;
    const [wait, setWait] = useState(true);
    
    useEffect(()=>{ // time to check if movie exists in Movie.jsx
        setTimeout(()=>{
            setWait(false);
        },2000)
    },[])

    return(
        <div className="flex flex-col justify-center items-center w-full rounded-[40px] shadow-shadow">
            <div className="absolute flex flex-col justify-center items-center w-full z-20">
                <Gnomes width={'50%'}/>
                {wait?
                    <div className="mt-5 w-12 h-12 border-4 border-white border-t-transparent rounded-full animate-spin"/>
                    :
                    <h3 className="text-[50px] leading-1 md:text-[81px] text-white">
                        <Countdown
                            date={
                                new Date(
                                    ( startTime?(startTime+renderTime):(Date.now()+(window.renderTime)*60*1000) )
                                )
                            }
                            renderer={renderer}
                            onComplete={()=>{setWait(true)}}
                        />
                    </h3>
                }
            </div>
            {/* <img src={thumbUrl} className="absolute w-full rounded-[inherit] opacity-30 blur-sm"/> */}
            <canvas width={1920} height={1080} className="w-full rounded-[inherit] bg-blue-default"/>
            {/* <div className="w-[50%] mt-5">
                <ProgressBar duration={4*60} style={'dark'}/>
            </div> */}
        </div>
    )
}
export default Count;