const Message = ({email})=>{
    return(
        <p className="sm:text-md lg:text-xl mb-5 max-w-[870px] mx-auto text-center">
            Processing typically takes about {window.renderTime} minutes.<br />
            Once your movie is ready, we’ll send a notification to <strong>{email}</strong>.<br />
            If you don’t see the email, please check your Spam folder.<br /><br />
            If this page appears stuck, feel free to refresh it—your progress won’t be affected.
        </p>
    )
}

export default Message