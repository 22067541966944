import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import utils from "./utils";
import keys from "./keys";

// flags
window.renderTime = 6;
const isLocal = utils.isLocal();
const isDev = utils.isDev();

// init firebase
let firebaseConfig = keys.firebase.prod;
if( isDev || isLocal ) firebaseConfig = keys.firebase.dev;
const firebaseApp = initializeApp(firebaseConfig);
console.log(firebaseApp.options.projectId);

// connectFunctionsEmulator(getFunctions(), "localhost", 3001);
// console.log('Functions emulator connected');

// production execution
if( !isDev && !isLocal && !utils.isMe() ){
    // google tag manager
    utils.loadGtag();
    
    // disable log
    window.consoleBU = console.log;
    console.log = ()=>{
        return true;
    }
}

// domain
const domain = "toonkids.com";
const domainUrl = "https://"+(( isDev || isLocal )?'dev.':'')+domain;

// storage
const bucketName = "storage"+(( isDev || isLocal )?'-dev':'')+"."+domain;
const storageDomain = 'https://'+bucketName;

//export
const Config = {
    domain: domainUrl,
    storageDomain: storageDomain
}
export default Config;